import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-404e26cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "view-container d-mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeader = _resolveComponent("BaseHeader")!
  const _component_OpenSourceAppItemVue = _resolveComponent("OpenSourceAppItemVue")!
  const _component_n_space = _resolveComponent("n-space")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseHeader),
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_n_space, { size: [80, 40] }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createBlock(_component_OpenSourceAppItemVue, {
              key: item.url,
              item: item
            }, null, 8, ["item"]))
          }), 128))
        ]),
        _: 1
      })
    ])
  ]))
}